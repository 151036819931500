<template>
    <div>
        <div class="fixdbox">
            <div class="left" @click="online_advice()">
                <!-- 在线咨询 -->
            </div>
            <div class="right" @click="Botell">
                <!-- <van-icon name="phone" />
                电话咨询 -->
            </div>
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {

        };
    },

    methods: {
        online_advice() {
            this.$GM.as()
        },
        Botell(phoneNumber) {
            this.$GM.callPhone(phoneNumber)
        },
    },
};
</script>
<style lang="scss" scoped>
.fixdbox {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 15px;
    padding-bottom: 5px;
    width: 97%;
    height: 41px;
    background:url(../assets/bottom.png);
    background-size: cover;
    left: 1.5%;
    .left {
        width: 50%;
        height: 41px;
        font-size: 24px;
        color: #fff;
        line-height: 50px;
        text-align: center;
    }

    .right {
        width: 50%;
        height: 41px;
        font-size: 24px;
        color: #fff;
        line-height: 50px;
        text-align: center;
    }

}
</style>