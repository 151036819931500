import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function (location) {
//   return originalPush.call(this, location).catch(err => { })
// };

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/Gszc',
        name: 'Gszc',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Gszc.vue')
      },
      {
        path: 'SearchPopup',
        name: 'SearchPopup',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/SearchPopup.vue')
      },
      {
        path: 'Result',
        name: 'Result',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Result.vue')
      },
      {
        path: 'AI',
        name: 'AI',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/AI.vue')
      },
      {
        path: 'Gsbg',
        name: 'Gsbg',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Gsbg.vue'),
       
      },
      {
        path: 'Dljz',
        name: 'Dljz',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Dljz.vue')
      },
      {
        path: 'Xkz',
        name: 'Xkz',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Xkz.vue'),
      },
      {
        path: 'Dlxkz',
        name: 'Dlxkz',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Dlxkz.vue')
      },
      {
        path: 'Spxkz',
        name: 'Spxkz',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Spxkz.vue')
      },
      {
        path: 'Jkxkz',
        name: 'Jkxkz',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Jkxkz.vue')
      },
      {
        path: 'Lwxkz',
        name: 'Lwxkz',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Lwxkz.vue')
      },
      {
        path: 'Jxxkz',
        name: 'Jxxkz',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Jxxkz.vue')
      },
      {
        path: 'Wsxkz',
        name: 'Wsxkz',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Wsxkz.vue')
      },
      {
        path: 'Gszx',
        name: 'Gszx',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Gszx.vue')
      },
      {
        path: 'Sbzc',
        name: 'Sbzc',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Sbzc.vue')
      },
      {
        path: 'Gyfy',
        name: 'Gyfy',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/Gyfy.vue')
      },
      {
        path: 'agreement',
        name: 'agreement',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/agreement.vue')
      },
      {
        path: 'yingsi',
        name: 'yingsi',
        component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/yingsi.vue')
      }
    ]
  },
  {
    path:'/login',
    name:'login',
    component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/login.vue'),
  },
  {
    path:'/NewHome',
    name:'NewHome',
    component: () => import(/* webpackChunkName: "SearchPopup" */ '../views/NewHome.vue'),
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */'../views/gszc.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to,from,next)=>{//beforeEach是router的钩⼦函数，在进⼊路由前执⾏
  // if(to.meta.title){//判断是否有标题
      document.title = "发业财税"
  // }
  next()  //执⾏进⼊路由，如果不写就不会进⼊⽬标页
})
export default router
